import React from 'react';
import { toSentenceCase } from '../../../FintelCheckRuleManager/hooks';
import {
  DefaultCell,
} from './Components';
import { StatusCell } from '../../../FintelCheckDetails/contracts/columns/Components';

export const statusColumns: TableColumn[] = [
  {
    dataField: 'status',
    text: 'Status',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '15%',
    cellOverflow: true,
    formatter: (val, row, _, __, ___, setOpenModal, setModalInfo) => (
      <StatusCell
        val={val}
        row={row}
        openModal={setOpenModal}
        setModalInfo={setModalInfo}
      />
    ),
  },
  {
    dataField: 'productName',
    text: 'Product',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '30%',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'productFeed',
    text: 'Rule Requirement',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '20%',
    formatter: (val) => <DefaultCell val={toSentenceCase(val)} />,
  },
  {
    dataField: 'expectedMatch',
    text: 'Expected Match',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '20%',
    formatter: (val) => <DefaultCell val={val} />,
  },
  {
    dataField: 'ruleRequired',
    text: 'Required',
    sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
    width: '15%',
    formatter: (val) => <DefaultCell val={val ? 'Yes' : 'No'} />,
  },
];
