import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import { CheckRulesOutputType, toSentenceCase } from '../../hooks';
import {
  Button, Modal, ErrorBanner,
} from '../../../../../../components';
import { dateFormatter } from '../../../../../../utils';
import { formatFeedItem } from '../RuleDetailsModal/hooks';
import { RULE_SUMMARY, NEW_RULE_SUMMARY, FIELD_LABELS } from './enums';
import { ALL_PRODUCTS } from '../AddRuleModal/hooks';

type RuleDetailsModalProps = {
  value?: CheckRulesOutputType
  closeModalFn: () => void
  isOpen: boolean
  errorMessage: string
  feedLoading: boolean
  parametersOpen?: boolean
  setParametersOpen: (state: boolean, feedName?: string) => void
  isNewRule?: boolean
  goBackFn?: () => void
  createRuleFn?: () => void
  createRuleLoading?: boolean
}

export const RuleSummaryModal = ({
  value, closeModalFn, isOpen, errorMessage, feedLoading, setParametersOpen, parametersOpen, isNewRule, goBackFn, createRuleFn, createRuleLoading,
}: RuleDetailsModalProps) => {
  const ENUM: Partial<typeof NEW_RULE_SUMMARY> = isNewRule ? NEW_RULE_SUMMARY : RULE_SUMMARY;
  return (
    <Modal
      isOpen={isOpen && !parametersOpen}
    >
      <Styled.ModalWrapperStyled>
        <ErrorBanner
          isOpen={!!errorMessage}
          message={errorMessage}
        />
        <Styled.TitleRow>
          <Styled.TitleText>
            {isNewRule && (
            <Styled.BackIconStyled
              onClick={goBackFn}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Styled.BackIconStyled>
            )}
            {ENUM.TITLE}
          </Styled.TitleText>
          {!isNewRule && (
            <Button
              onClick={closeModalFn}
              theme="secondary"
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
        </Styled.TitleRow>
        {isNewRule && (
          <Styled.ReviewTextStyled>
            {ENUM?.REVIEW_NOTICE}
          </Styled.ReviewTextStyled>
        )}
        <Styled.SectionWrapper>
          <Styled.CategoryWrapper>
            <Styled.CategoryHeader>
              {FIELD_LABELS.RULE_NAME}
            </Styled.CategoryHeader>
            <Styled.CategoryValue>
              {value?.ruleName}
            </Styled.CategoryValue>
          </Styled.CategoryWrapper>
          <Styled.CategoryWrapper>
            <Styled.CategoryHeader>
              {FIELD_LABELS.CREATED_ON}
            </Styled.CategoryHeader>
            <Styled.CategoryValue>
              {dateFormatter(new Date(value?.startDate || ''))}
            </Styled.CategoryValue>
          </Styled.CategoryWrapper>
        </Styled.SectionWrapper>
        <Styled.SubTitleText>
          {ENUM.PRODUCTS_RULE_APPLY}
        </Styled.SubTitleText>
        <Styled.SectionWrapper>
          <Styled.CategoryWrapper>
            <Styled.CategoryHeader>
              {FIELD_LABELS.PRODUCT_CATEGORY}
            </Styled.CategoryHeader>
            <Styled.CategoryValue>
              {value?.productCategory}
            </Styled.CategoryValue>
          </Styled.CategoryWrapper>
          <Styled.CategoryWrapper>
            <Styled.CategoryHeader>
              {FIELD_LABELS.PRODUCTS}
            </Styled.CategoryHeader>
            <Styled.CategoryValue>
              {value?.applicableProducts}
            </Styled.CategoryValue>
          </Styled.CategoryWrapper>
        </Styled.SectionWrapper>
        <Styled.SubTitleText>
          {ENUM.MINIMUM_CONDITIONS}
        </Styled.SubTitleText>
        <Styled.SectionWrapper>
          <Styled.CategoryWrapper>
            <Styled.CategoryHeader>
              {FIELD_LABELS.NOMINATED_FIELD}
            </Styled.CategoryHeader>
            <Styled.CategoryValue>
              {value?.nominatedField}
            </Styled.CategoryValue>
          </Styled.CategoryWrapper>
        </Styled.SectionWrapper>
        <Styled.SubTitleText>
          {ENUM.PARAMETERS_CHECK}
        </Styled.SubTitleText>
        {value?.productFeed.map((item, index) => (
          <React.Fragment key={`details${value.id}.${item.productFeed}.${item.id}`}>
            {index !== 0 && (
              <Styled.AndSection>
                <Styled.AndText>
                  {ENUM.AND}
                </Styled.AndText>
                <Styled.AndLine />
              </Styled.AndSection>
            )}
            <Styled.SectionWrapper>
              <Styled.CategoryWrapper>
                <Styled.CategoryHeader>
                  {FIELD_LABELS.RULE_REQUIREMENT}
                </Styled.CategoryHeader>
                <Styled.CategoryValue>
                  {toSentenceCase(item.productFeed)}
                </Styled.CategoryValue>
                {item.required && (
                  <Styled.CheckboxStyled
                    label="Required"
                    checked
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onChange={() => {}}
                    disabled
                    theme="secondary"
                  />
                )}
              </Styled.CategoryWrapper>
              <Styled.CategoryWrapper>
                <Styled.CategoryHeader>
                  {FIELD_LABELS.RULE_DATA}
                </Styled.CategoryHeader>
                {value.applicableProducts === ALL_PRODUCTS
                  ? (
                    <Styled.DetailsButton
                      onClick={() => setParametersOpen(true, item.productFeed)}
                      loading={feedLoading}
                      disabled={feedLoading || !!errorMessage}
                      theme="quinary"
                    >
                      {ENUM.VIEW_PRODUCT_FEED_BUTTON}
                    </Styled.DetailsButton>
                  ) : (
                    <Styled.CategoryValue>
                      {isNewRule ? item.productFeedData : formatFeedItem(item.productFeedData, item.productFeed)}
                    </Styled.CategoryValue>
                  )}
              </Styled.CategoryWrapper>
            </Styled.SectionWrapper>
          </React.Fragment>
        ))}
        {isNewRule && (
          <Styled.NavButtonsWrapperStyled>
            <Button
              theme="primary"
              onClick={() => {
                if (createRuleFn) createRuleFn();
              }}
              width="120px"
              loading={createRuleLoading}
            >
              {ENUM.CREATE_RULE_BUTTON}
            </Button>
            <Button
              theme="secondary"
              onClick={closeModalFn}
              width="100px"
            >
              {ENUM.CANCEL_BUTTON}
            </Button>
          </Styled.NavButtonsWrapperStyled>
        )}
      </Styled.ModalWrapperStyled>
    </Modal>
  );
};
