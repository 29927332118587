import { useLazyQuery, useMutation } from '@apollo/client';
import {
  useEffect, useState, useMemo,
} from 'react';
import { CheckRulesOutputType, toSentenceCase } from '../../../hooks';
import { FeedCategoryType, formatFeedItem } from '../../RuleDetailsModal/hooks';
import { CHECK_UNIQUE_RULE_NAME, LIST_PRODUCTS_AND_CATEGORIES } from '../graphql/queries';
import { CREATE_FINTEL_CHECK_RULE } from '../graphql/mutations';
import { useToast, useUserInfo } from '../../../../../../../hooks';
import { LIST_PRODUCT_FEED_DATA } from '../../RuleDetailsModal/graphql/queries';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '../enums';
import { TOAST_ERR_MESSAGES_NO_PAGE } from '../../../../../../../utils';

export type ProductType = {
  id: string
  customizedProductId: string
  productName: string
  productCategory: string
  type: string
  validRuleRequirements: {
    name: string
    value: string
  }[]
}

export type ListProductCategoriesReturnType = {
  category: string
  productFields: string[]
  products: ProductType[]
}

export type CriteriaOption = {
  value: string,
  label: string,
  description: string,
  validFor: string[]
};

type ProductFeedType = {
  id: number,
  productFeed: string,
  productFeedData: string,
  required: boolean
}

type CreateCheckRuleInputType = {
  merchantId: string,
  applicableProducts: string,
  productCategory: string,
  productId: string,
  nominatedField: string,
  productFeed: ProductFeedType[],
  customText: {
    id: number,
    customText: string
    required: boolean
  }[],
  customizedProductId: string,
  productName: string,
  ruleName: string,
  startDate: Date,
}

export type RuleRequirementType = {
  id: number
  productFeed: SelectOption | undefined
  value: string | undefined
  required: boolean
};

export const ALL_PRODUCTS = 'All Products';
export const NO_PRODUCTS = 'No Specific Products';
export const SPECIFIC_PRODUCT = 'product';

const CATEGORY_VALUES = {
  PRODUCT_FIELD: 'productField',
  PRODUCT_NAME: 'productName',
  CUSTOM_TERM: 'customTerm',
};

const categoryOptions: CriteriaOption[] = [
  {
    value: CATEGORY_VALUES.PRODUCT_FIELD,
    label: 'Product Field',
    description: 'Match information based on a product field',
    validFor: [NO_PRODUCTS, SPECIFIC_PRODUCT],
  },
  {
    value: CATEGORY_VALUES.PRODUCT_NAME,
    label: 'Product Name',
    description: 'Match information based on a product name',
    validFor: [ALL_PRODUCTS, SPECIFIC_PRODUCT],
  },
  {
    value: CATEGORY_VALUES.CUSTOM_TERM,
    label: 'Custom term or phrase',
    description: 'Match information based on a custom content',
    validFor: [NO_PRODUCTS, SPECIFIC_PRODUCT],
  },
];

export const useAddRuleModal = (setModalState:(state: boolean) => void, newRuleCreated:() => void) => {
  // Global Constants
  const { hookWhoAmI } = useUserInfo();
  const { hookShowToast } = useToast();
  // Overall Values
  const [currentModal, setCurrentModal] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Step One's State
  const [ruleName, setRuleName] = useState<string>('');
  const [selectedProductCategory, setSelectedProductCategory] = useState<SelectOption>();
  const [productCategoryList, setProductCategoryList] = useState<SelectOption[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<ProductType>();
  const [selectedProductDropdown, setSelectedProductDropdown] = useState<SelectOption>();
  const [allProductsList, setAllProductsList] = useState<ListProductCategoriesReturnType[]>([]);
  const [availableProducts, setAvailableProducts] = useState<SelectOption[]>([]);
  const [ruleNameError, setRuleNameError] = useState<string>('');

  // Step Two's State
  const [selectedCriteria, setSelectedCriteria] = useState<SelectOption>();
  const [selectedProductFeed, setSelectedProductFeed] = useState<SelectOption>();
  const [customTermValue, setCustomTermValue] = useState<string>('');

  // Step Three's State
  const [requirements, setRequirements] = useState<RuleRequirementType[]>([]);
  const [stepThreeErrors, setStepThreeErrors] = useState<string[]>([]);
  const [onBlurOn, setOnBlurOn] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);

  // Step Four's State (New Rule Summary)
  const [summary, setSummary] = useState<CheckRulesOutputType | undefined>();
  const [createRuleError, setCreateRuleError] = useState<string>('');

  // For Product Feed Table for if 'All Products' Selected
  const [productFeedOpen, setProductFeedOpen] = useState<boolean>(false);
  const [feedData, setFeedData] = useState<FeedCategoryType[]>([]);
  const [tableData, setTableData] = useState<FeedCategoryType>();

  // Cancel Modal State
  const [cancelOpen, setCancelOpen] = useState<boolean>(false);

  // Queries & Mutations
  const [checkUniqueName, { loading: checkUniqueNameLoading }] = useLazyQuery(CHECK_UNIQUE_RULE_NAME, {
    fetchPolicy: 'no-cache',
  });
  const [listProductFeedData, { loading: productFeedLoading }] = useLazyQuery(LIST_PRODUCT_FEED_DATA);

  const [listAvailableProductsForRule, { loading: listAvailableProductsLoading }] = useLazyQuery(LIST_PRODUCTS_AND_CATEGORIES);

  const [createNewCheckRule, { loading: createNewCheckRuleLoading }] = useMutation(CREATE_FINTEL_CHECK_RULE);

  /*
   * Overall Handlers
   */
  const setCurrentModalHandler = (modalNum: number) => {
    setCurrentModal(modalNum);
  };

  const getBackendValues = async () => {
    setErrorMessage('');
    const { data, error } = await listAvailableProductsForRule({
      variables: {
        input: {
          merchantId: hookWhoAmI?.companyId?.toString(),
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });
    if (error) {
      setErrorMessage(error.message);
    }

    if (data && data.listAvailableProductsForRule.products) {
      setAllProductsList(data.listAvailableProductsForRule.products);
      setProductCategoryList(data.listAvailableProductsForRule.products.map(({ category }: {category: string}) => ({ label: category, value: category })));
    } else {
      setAllProductsList([]);
      setProductCategoryList([]);
    }
  };

  const cancelButtonHandler = (state: boolean) => {
    setCancelOpen(state);
  };

  const backButtonHandler = () => {
    setCurrentModalHandler(currentModal - 1);
  };

  const resetModalValues = (startPoint: number) => {
    if (startPoint < 1) {
      setRuleName('');
      setSelectedProductCategory(undefined);
      setRuleNameError('');
      setErrorMessage('');
    }
    if (startPoint < 2) {
      setSelectedProduct(undefined);
      setSelectedProductDropdown(undefined);
    }
    if (startPoint < 3) {
      setSelectedCriteria(undefined);
    }
    if (startPoint < 4) {
      setSelectedProductFeed(undefined);
      setCustomTermValue('');
      setCreateRuleError('');
    }
    if (startPoint < 5) {
      setRequirements([]);
      setStepThreeErrors([]);
      setOnBlurOn(false);
    }
  };

  /*
   * Cancel Modal Handlers and Logic
   */
  const exitAddRuleModalHandler = () => {
    resetModalValues(0);
    cancelButtonHandler(false);
    setModalState(false);
    setCurrentModal(1);
  };

  /*
   * Step One Handlers & Logic
   */
  const setRuleNameHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
    setRuleNameError('');
    setRuleName(e.target.value);
  };

  const setAvailableProductsHandler = (newProductCategory: SelectOption) => {
    if (allProductsList) {
      const index = allProductsList.findIndex((category) => category.category === newProductCategory.value);
      if (index === -1) {
        setAvailableProducts([]);
        return;
      }
      const validOptions = allProductsList[index].products
        .filter((product) => product.productCategory === newProductCategory.value)
        .map((product) => (
          {
            label: product.productName ? `${product.customizedProductId} - ${product.productName}` : product.customizedProductId,
            value: product.id,
          }
        ));
      const newProductList:SelectOption[] = validOptions;
      setAvailableProducts(newProductList);
    }
  };

  const setSelectedProductCategoryHandler = (newSelectedProductCategory: SelectOption) => {
    setSelectedProductCategory(newSelectedProductCategory);
    setAvailableProductsHandler(newSelectedProductCategory);
    resetModalValues(1);
  };

  const setSelectedProductHandler = (newSelectedProduct: SelectOption) => {
    const index = allProductsList.findIndex((category) => category.category === selectedProductCategory?.value);
    if (index === -1) setSelectedProduct(undefined);
    else setSelectedProduct(allProductsList[index].products.find((product) => product.id === newSelectedProduct.value));
    setSelectedProductDropdown(newSelectedProduct);
    resetModalValues(2);
  };

  const canContinueOne = !!(ruleName && selectedProductCategory && selectedProductDropdown);

  const navigateModalTwo = async () => {
    // Error Logic for missing fields
    if (!canContinueOne) return;
    setErrorMessage('');
    const { data, error } = await checkUniqueName({
      variables: {
        input: {
          merchantId: hookWhoAmI?.companyId?.toString(),
          ruleName,
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    if (error) {
      setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(error.message));
      return;
    }

    if (data && data.checkUniqueRuleName.unique === true) {
      setRuleNameError('');
      setCurrentModal(2);
    } else {
      setRuleNameError(ERROR_MESSAGES.RULE_NAME_TAKEN);
    }
  };

  /*
   * Step 2 Handlers & Logic
   */
  const criteriaList = useMemo(() => categoryOptions.filter((opt) => opt.validFor.includes(selectedProduct?.type || '')), [selectedProduct]);

  const productFeedList: SelectOption[] = useMemo(() => {
    if (allProductsList) {
      const index = allProductsList.findIndex((category) => category.category === selectedProduct?.productCategory);
      if (index === -1) return [];
      return allProductsList[index].productFields.map((opt): SelectOption => ({ label: toSentenceCase(opt), value: opt }));
    }
    return [];
  }, [selectedProduct]);

  const setSelectedCriteriaHandler = (value: SelectOption) => {
    const match = categoryOptions.find((opt) => opt.value === value.value);
    if (match) setSelectedCriteria({ label: match?.label, value: match?.value });
    resetModalValues(3);
  };

  const setSelectedProductFeedHandler = (value: SelectOption) => {
    setSelectedProductFeed(value);
  };

  const setCustomTermValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomTermValue(e.target.value);
  };

  const canContinueTwo = (() => {
    if (!selectedCriteria) return false;
    if (selectedCriteria.value === CATEGORY_VALUES.PRODUCT_NAME) return true;
    if (selectedCriteria.value === CATEGORY_VALUES.PRODUCT_FIELD && selectedProductFeed) return true;
    if (selectedCriteria.value === CATEGORY_VALUES.CUSTOM_TERM && customTermValue) return true;
    return false;
  })();

  const navigateModalThree = () => {
    setCurrentModal(3);
  };

  /*
   * Step 3 Handlers & Logic
   */
  const requirementChoices: SelectOption[] = useMemo(() => {
    if (!allProductsList) return [];
    if (selectedProduct?.productCategory) {
      if (selectedProduct.type === SPECIFIC_PRODUCT) {
        const index = allProductsList.findIndex((category) => category.category === selectedProduct?.productCategory);
        if (index === -1) return [];
        const fieldOptions = allProductsList[index].productFields;
        return fieldOptions
          .filter((opt) => selectedProduct.validRuleRequirements.reduce((found, r) => (r.name === opt || found), false))
          .filter((opt) => !requirements.reduce((found, r) => (r.productFeed?.value === opt || found), false))
          .map((opt): SelectOption => ({ label: toSentenceCase(opt), value: opt }));
      }
      if (selectedProduct.type === ALL_PRODUCTS) {
        const fieldOptions = feedData.filter((feed) => feed.values.length > 0).map((feed) => feed.category);
        return fieldOptions
          .filter((opt) => !requirements.reduce((found, r) => (r.productFeed?.value === opt || found), false))
          .map((opt): SelectOption => ({ label: toSentenceCase(opt), value: opt }));
      }
      return productFeedList;
    }
    return [];
  }, [selectedProduct, requirements]);

  const canContinueThree = stepThreeErrors.length < 1 && requirements.length > 0;

  const validateInputFields = () => {
    const errors: string[] = [];
    requirements.forEach((r) => {
      if (!r.productFeed) {
        errors[(1000000000 + r.id)] = ERROR_MESSAGES.MISSING_PRODUCT_FEED;
      }
    });
    if (selectedProduct?.type === NO_PRODUCTS) {
      requirements.forEach((r) => {
        if (errors[(1000000000 + r.id)]) return;
        if (r.productFeed?.value === 'annualFee') {
          const regex = /^\$\d+(\.\d{1,2})?$/;
          const result = regex.test(r.value || '');
          if (!result) errors[r.id] = ERROR_MESSAGES.MISSING_CURRENCY;
        } else if (r.productFeed?.value !== 'legalReference') {
          const regex = /(^100|^\d{1,2}(\.\d{1,2})?)%$/;
          const result = regex.test(r.value || '');
          if (!result) errors[r.id] = ERROR_MESSAGES.MISSING_PERCENTAGE;
        } else if (!r.value) errors[r.id] = ERROR_MESSAGES.MISSING_VALUE;
      });
    }
    setStepThreeErrors(errors);
    setValidate(false);
    if (errors.length > 0) return false;
    return true;
  };
  if (validate) validateInputFields();

  const addBlankRequirement = () => {
    const newRequirement: RuleRequirementType = {
      id: requirements ? (requirements.reduce((highest, curr) => (highest > curr.id ? highest : curr.id), 0) + 1) : 1,
      productFeed: undefined,
      value: undefined,
      required: false,
    };
    setRequirements([...requirements, newRequirement]);
  };

  const removeRequirementById = (id: number) => {
    const newRequirements = requirements.filter((req) => req.id !== id);
    setRequirements(newRequirements);
    setValidate(true);
  };

  const updateRequirementFeed = (id: number, newField: SelectOption) => {
    // No Specific Product Case
    if (selectedProduct?.type === NO_PRODUCTS) {
      const newRequirements = requirements.map((req): RuleRequirementType => {
        if (req.id === id) {
          return {
            ...req,
            productFeed: newField,
            value: '',
          };
        }
        return req;
      });
      setRequirements(newRequirements);
      return;
    }
    // Specific Product Case
    if (selectedProduct?.type === SPECIFIC_PRODUCT) {
      const newValue = selectedProduct.validRuleRequirements.find((rule) => rule.name === newField.value)?.value;
      const newRequirements = requirements.map((req): RuleRequirementType => {
        if (req.id === id) {
          return {
            ...req,
            productFeed: newField,
            value: formatFeedItem(newValue, newField.value),
          };
        }
        return req;
      });
      setRequirements(newRequirements);
    }
    // All Products Case
    if (selectedProduct?.type === ALL_PRODUCTS) {
      const newValue = selectedProduct.validRuleRequirements.find((rule) => rule.name === newField.value)?.value;
      const newRequirements = requirements.map((req): RuleRequirementType => {
        if (req.id === id) {
          return {
            ...req,
            productFeed: newField,
            value: formatFeedItem(newValue, newField.value),
          };
        }
        return req;
      });
      setRequirements(newRequirements);
    }
  };

  const updateRequirementValue = (id: number, value: React.ChangeEvent<HTMLInputElement>) => {
    const newRequirements = requirements.map((req): RuleRequirementType => {
      if (req.id === id) {
        return {
          ...req,
          value: value.target.value,
        };
      }
      return req;
    });
    setRequirements(newRequirements);
  };

  const updateRequirementRequired = (id: number, state: boolean) => {
    const newRequirements = requirements.map((req): RuleRequirementType => {
      if (req.id === id) {
        return {
          ...req,
          required: state,
        };
      }
      return req;
    });
    setRequirements(newRequirements);
  };

  const onBlurCheck = () => {
    if (onBlurOn) validateInputFields();
  };

  const navigateModalFour = () => {
    if (!validateInputFields()) {
      setOnBlurOn(true);
      return;
    }
    const newSummary: CheckRulesOutputType = {
      id: 0,
      merchantId: hookWhoAmI.companyId?.toString() || '',
      applicableProducts: selectedProduct?.type === SPECIFIC_PRODUCT ? `${selectedProduct?.customizedProductId} - ${selectedProduct?.productName}` : selectedProduct?.type || '',
      productCategory: selectedProduct?.productCategory || '',
      productId: selectedProduct?.id || '',
      customizedProductId: selectedProduct?.customizedProductId || '',
      productName: selectedProduct?.productName || selectedProduct?.type || '',
      nominatedField: selectedProductFeed?.label || customTermValue || selectedCriteria?.label || '',
      ruleName,
      customText: [],
      productFeed: (requirements.map((req) => (
        {
          id: req.id,
          productFeed: req?.productFeed?.value || '',
          productFeedData: req.value || '',
          required: req.required,
        }))),
      status: 'Active',
      startDate: (new Date()).toString(),
      endDate: null,
      lastRun: '',
      reviewCount: null,
      failCount: null,
      nfCount: null,
    };
    setSummary(newSummary);
    setCurrentModalHandler(4);
  };

  /*
   * Step 4 (New Rule Summary) Handlers & Logic
   */
  const createRuleHandler = async () => {
    setCreateRuleError('');
    const newDate = new Date();
    newDate.setUTCHours(0, 0, 0, 0);
    if (!selectedProduct || !selectedCriteria) return;
    const newRuleInput:CreateCheckRuleInputType = {
      merchantId: hookWhoAmI?.companyId?.toString() || '',
      applicableProducts: selectedProduct?.type === SPECIFIC_PRODUCT ? `${selectedProduct?.customizedProductId} - ${selectedProduct?.productName}` : selectedProduct?.type || '',
      productCategory: selectedProduct.productCategory,
      productId: selectedProduct.type === SPECIFIC_PRODUCT ? selectedProduct.id : '',
      nominatedField: selectedProductFeed?.label || customTermValue || selectedCriteria?.label || '',
      productFeed: requirements.map((req, index):ProductFeedType => (
        {
          id: index,
          productFeed: req.productFeed?.value || '',
          productFeedData: req?.value || '',
          required: req.required,
        })),
      customText: [],
      customizedProductId: selectedProduct.type === SPECIFIC_PRODUCT ? selectedProduct.customizedProductId : '',
      productName: selectedProduct.productName,
      ruleName,
      startDate: newDate,
    };

    const { errors } = await createNewCheckRule({
      variables: {
        input: newRuleInput,
      },
      onError(err) {
        setCreateRuleError(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    if (errors) {
      setCreateRuleError(TOAST_ERR_MESSAGES_NO_PAGE(errors[0].message));
    } else {
      hookShowToast(SUCCESS_MESSAGES.CREATED_RULE_TOAST);
      exitAddRuleModalHandler();

      // Clear Cache after update as it is no longer valid.
      newRuleCreated();
    }
  };

  const setFeedDataHandler = async () => {
    if (selectedProduct?.type !== ALL_PRODUCTS) return;
    setErrorMessage('');
    const { data, error } = await listProductFeedData({
      variables: {
        input: {
          merchantId: hookWhoAmI?.companyId?.toString(),
          productCategory: selectedProduct?.productCategory,
          parameters: selectedProduct?.validRuleRequirements.map((feed) => feed.name),
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });
    if (error) {
      setErrorMessage(error?.message);
      return;
    }

    if (data.listProductFeedData) {
      setFeedData(data.listProductFeedData.data);
    }
  };

  const handleSetFeedDataOpen = (state: boolean, feedName?: string) => {
    if (state === false) {
      setProductFeedOpen(false);
      return;
    }
    if (!feedName || !feedData) return;
    const index = feedData.findIndex((feed) => feed.category === feedName);
    if (index === -1) {
      setTableData({ category: feedName || '', values: [] });
      setProductFeedOpen(true);
      return;
    }
    setTableData(structuredClone(feedData[index]));
    setProductFeedOpen(true);
  };

  // Get the correct feed data when the product selected is ALL PRODUCTS
  useEffect(() => {
    if (selectedProduct?.type === ALL_PRODUCTS) {
      setFeedDataHandler();
    }
  }, [selectedProduct]);

  // Get the values for Step One dropdowns
  useEffect(() => {
    getBackendValues();
  }, []);

  return {
    // Overall Values
    hookCurrentModal: currentModal,
    hookCancelOpen: cancelOpen,
    hookProduct: selectedProduct,
    hookSetCurrentModal: setCurrentModal,
    hookCancelButtonHandler: cancelButtonHandler,
    hookBackButtonHandler: backButtonHandler,
    hookErrorMessage: errorMessage,

    // Step One
    // Rule Name
    hookRuleName: ruleName,
    hookSetRuleName: setRuleNameHandler,
    hookRuleNameError: ruleNameError,
    // Product Category
    hookProductCategoryList: productCategoryList,
    hookSelectedProductCategory: selectedProductCategory,
    hookSetSelectedProductCategory: setSelectedProductCategoryHandler,
    hookProductCategoryLoading: listAvailableProductsLoading,
    // Product
    hookSelectedProduct: selectedProductDropdown,
    hookProductsList: availableProducts,
    hookSetSelectedProduct: setSelectedProductHandler,
    // Navigate
    hookCanContinueOne: canContinueOne,
    hookNavigateModalTwo: navigateModalTwo,
    hookLoadingNavigateModalTwo: checkUniqueNameLoading,

    // Step Two
    // Criteria
    hookSelectedCriteria: selectedCriteria,
    hookCriteriaList: criteriaList,
    hookSetSelectedCriteriaHandler: setSelectedCriteriaHandler,
    // Product Feed Select
    hookProductFeedList: productFeedList,
    hookSelectedProductFeed: selectedProductFeed,
    hookSetSelectedProductFeed: setSelectedProductFeedHandler,
    // Product Custm Term
    hookCustomTerm: customTermValue,
    hookSetCustomTerm: setCustomTermValueHandler,
    // Navigate
    hookCanContinueTwo: canContinueTwo,
    hookNavigateModalThree: navigateModalThree,

    // Step Three
    // Add Rule Requirement
    hookAddRequirement: addBlankRequirement,
    hookRequirements: requirements,
    hookRemoveRequirement: removeRequirementById,
    hookUpdateRequirementFeed: updateRequirementFeed,
    hookUpdateRequirementRequired: updateRequirementRequired,
    hookRequirementChoices: requirementChoices,
    // No Specific Products Only
    hookUpdateRequirementValue: updateRequirementValue,
    hookStepThreeErrors: stepThreeErrors,
    hookValidateInputFields: validateInputFields,
    hookOnBlurCheck: onBlurCheck,
    // Navigate
    hookCanContinueThree: canContinueThree,
    hookNavigateModalFour: navigateModalFour,

    // Step Four/Summary
    hookSummary: summary,
    hookCreateRuleError: createRuleError,
    hookCreateRule: createRuleHandler,
    hookCreateRuleLoading: createNewCheckRuleLoading,

    // Feed Data Modal
    hookHandleSetFeedDataOpen: handleSetFeedDataOpen,
    hookTableData: tableData,
    hookProductFeedOpen: productFeedOpen,
    hookFeedDataLoading: productFeedLoading,

    // Leave Rule
    hookExitAddRuleModalHandler: exitAddRuleModalHandler,
  };
};
