import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppWrapper, Button, PageTitle, Pagination, Select, Table, FixedFooter, ErrorBanner, RecordsDropdown,
} from '../../../../components';
import { path, RECORDS_PER_PAGE_OPTIONS_WITH_150 } from '../../../../utils';
import { columns } from './contracts';
import { useFintelRuleManager } from './hooks';
import { RuleDetailsModal, AddRuleModal, DeactivateRuleModal } from './components';
import { RULE_MANAGER } from './enums';
import { DefaultPropsType } from '../../../../types';
import DeleteRuleModal from './modal/DeleteRuleModal';

import * as Styled from './styles';

type FintelCheckRuleManagerProps = DefaultPropsType;

const FintelCheckRuleManager = ({ permissionsCodeList = [] }: FintelCheckRuleManagerProps) => {
  const { hookIsReadOnlyList, ...hook } = useFintelRuleManager(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.WrapperStyled>
        <ErrorBanner
          isOpen={!!hook.hookErrorMessage}
          message={hook.hookErrorMessage}
        />
        <Styled.HeaderStyled>
          <PageTitle
            isLoading={hook.hookDropdownsLoading || hook.hookRuleManagerLoading}
            loadingText={hook.hookLoadingMessage}
          >
            {path.ruleManager.name}
          </PageTitle>

          <Button
            theme="tertiary"
            onClick={() => hook.hookSetIsAddRuleModalOpen(true)}
            disabled={isReadOnly}
          >
            <FontAwesomeIcon
              icon={faPlus}
              style={{ marginRight: 8 }}
            />
            {RULE_MANAGER.ADD_RULE_BUTTON}
          </Button>
        </Styled.HeaderStyled>

        <Styled.FiltersStyled>
          <Select
            label={RULE_MANAGER.LABELS.PRODUCT_CATEGORY}
            name="category"
            onChange={hook.hookSetSelectedProductCategory}
            options={hook.hookProductCategoriesOptions}
            selected={hook.hookSelectedProductCategory}
            isLoading={hook.hookDropdownsLoading}
            isDisabled={hook.hookDropdownsLoading || !!hook.hookDropdownsError}
          />
          <Select
            label={RULE_MANAGER.LABELS.PRODUCT_NAME}
            name="product"
            onChange={hook.hookSetSelectedProductName}
            options={hook.hookProductNameOptions}
            selected={hook.hookSelectedProductName}
            isLoading={hook.hookDropdownsLoading}
            isDisabled={hook.hookDropdownsLoading || !!hook.hookDropdownsError}
          />
          <Select
            label={RULE_MANAGER.LABELS.STATUS}
            name="status"
            onChange={hook.hookSetSelectedStatus}
            options={hook.hookStatusOptions}
            selected={hook.hookSelectedStatus}
            isLoading={hook.hookDropdownsLoading}
            isDisabled={hook.hookDropdownsLoading || !!hook.hookDropdownsError}
          />
          <Select
            label={RULE_MANAGER.LABELS.RULES}
            name="rules"
            onChange={hook.hookSetSelectedRule}
            options={hook.hookRuleNameOptions}
            selected={hook.hookSelectedRule}
            isLoading={hook.hookDropdownsLoading}
            isDisabled={hook.hookDropdownsLoading || !!hook.hookDropdownsError}
          />
        </Styled.FiltersStyled>

        <Styled.RecordsStyled>
          <Styled.ExtendedRecordsStyled>
            <RecordsDropdown
              selected={hook.hookSelectedRecordsAmount}
              options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
              currentPage={hook.hookPage}
              totalRecords={hook.hookTotalValues}
              onChange={hook.hookSetSelectedRecordsAmount}
            />
          </Styled.ExtendedRecordsStyled>

          <Button
            theme="text-only"
            onClick={hook.hookResetDropdowns}
          >
            {RULE_MANAGER.CLEAR_FORM_BUTTON}
          </Button>
        </Styled.RecordsStyled>

        <Table
          columns={columns(isReadOnly, hook.handleDelete)}
          data={hook.hookData}
          headerCheckBox={hook.hookHeaderCheck}
          handleHeaderCheckBox={hook.hookHeaderCheckOnChange}
          handleCheckBox={hook.hookHandleChangeCheck}
          checkInactive={hook.hookCheckTableRowInactive}
          sortColumn={hook.hookSortColumn}
          onSort={hook.hookSortByHandler}
          setOpenModal={() => hook.hookSetIsDetailsModalOpenHandler(true)}
          setModalInfo={hook.hookSetModalRuleIdHandler}
          isLoading={hook.hookTableLoading}
        />
        {hook.hookData && (
          <Pagination
            total={hook.hookTotalPages}
            currentPage={hook.hookData.length > 0 ? hook.hookPage : 0}
            onPageChange={hook.hookChangePage}
          />
        )}
      </Styled.WrapperStyled>

      <FixedFooter>
        <Styled.FooterWrapperStyled>
          <Button
            onClick={() => hook.hookSetIsDeactivateRuleModalOpen(true)}
            disabled={hook.hookIsDeavtivateButtonDisabled || isReadOnly}
          >
            {RULE_MANAGER.DEACTIVATE_RULE}
          </Button>
        </Styled.FooterWrapperStyled>
      </FixedFooter>

      <RuleDetailsModal
        value={hook.hookModalRuleId}
        closeModalFn={hook.hookSetIsDetailsModalOpenHandler}
        isOpen={hook.hookIsDetailsModalOpen}
      />
      <AddRuleModal
        closeModalFn={hook.hookSetIsAddRuleModalOpen}
        isOpen={hook.hookIsAddRuleModalOpen}
        newRuleCreated={hook.hookNewRuleCreated}
      />
      <DeactivateRuleModal
        closeModalFn={() => hook.hookSetIsDeactivateRuleModalOpen(false)}
        isOpen={hook.hookIsDeactivateRuleModalOpen}
        deactivateRuleFn={hook.hookDeactivateRule}
        errorMessage={hook.hookDeactivateRuleError}
        isLoading={hook.hookDeactivateLoading}
      />
      <DeleteRuleModal hook={hook} />
    </AppWrapper>
  );
};

export default FintelCheckRuleManager;
