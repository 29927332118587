export const RULE_MANAGER = {
  TITLE: 'Delete Rule',
  ADD_RULE_BUTTON: 'Add Rule',
  CLEAR_FORM_BUTTON: 'Clear Form',
  DEACTIVATE_RULE: 'Deactivate Rule',
  LABELS: {
    PRODUCT_CATEGORY: 'Product Category',
    PRODUCT_NAME: 'Product Name/ID',
    STATUS: 'Status',
    RULES: 'Rules',
  },
  RULE_DEACTIVATED_TOAST: 'Rule successfully deactivated.',
  RULE_DELETED_TOAST: 'Rule successfully deleted.',
  RULE_DELETE_WARNING: 'Are you sure you want to delete this rule?',
  RULE_DELETE_CANCEL: 'Cancel',
  RULE_DELETE: 'Delete',
};
