/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../../styles/theme';

import * as Styled from './styles';

type InputTextProps = {
  label?: string;
  name?: string;
  value: string | number;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  maxLength?: number;
  error?: any;
  width?: string;
  height?: string;
  className?: string;
  rows?: number;
  theme?: 'default' | 'readonly' | 'generateCode' | 'readonlySecondary';
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  tooltip?: string | React.ReactNode;
  tooltipWidth?: number;
  isLink?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
};

export const InputTextarea = ({
  label = '',
  name,
  value,
  placeholder = '',
  disabled = false,
  required = false,
  readonly = false,
  maxLength = 1000,
  error = false,
  width = '100%',
  height = 'auto',
  className,
  rows,
  theme = 'default',
  onChange,
  tooltip,
  tooltipWidth,
  isLink,
  onBlur,
}: InputTextProps) => (
  <Styled.WrapperStyled
    width={width}
    className={className}
  >
    {label && (
      <Styled.LabelStyled>
        {label}
        {' '}
        {required && <Styled.RequiredStyled>*</Styled.RequiredStyled>}
        {' '}
        {tooltip && (
          <Styled.TooltipStyled
            width={tooltipWidth}
            text={tooltip}
          />
        )}
      </Styled.LabelStyled>
    )}
    <Styled.InputContainerStyled
      error={error}
      theme={theme}
      disabled={disabled}
      readonly={readonly}
    >
      {!isLink && (
      <Styled.InputStyled
        name={name}
        height={height}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        readOnly={readonly}
        maxLength={maxLength}
        rows={rows}
        onChange={onChange}
        onBlur={onBlur}
        theme={theme}
      />
      )}
      {isLink && (
      <Styled.LinkStyled
        href={typeof value === 'string' ? value : '/'}
        target="_blank"
        rel="noreferrer"
      >
        {value}
      </Styled.LinkStyled>
      )}
      {!!error && (
      <FontAwesomeIcon
        icon={faTimes}
        style={{ color: colors.color14, position: 'absolute', paddingRight: '10px' }}
      />
      )}
    </Styled.InputContainerStyled>

    {error && (
    <Styled.ErrorStyled>{error}</Styled.ErrorStyled>
    )}
  </Styled.WrapperStyled>
);
