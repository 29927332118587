import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useToast, useUserInfo } from '../../../../../hooks';
import { LIST_FINTEL_CHECK_RULE, RULE_MANAGER_DROPDOWNS_INFO } from '../graphql/queries';
import { DEACTIVATE_RULES_BY_IDS } from '../graphql/mutations';
import { RECORDS_PER_PAGE_OPTIONS_WITH_150, TOAST_ERR_MESSAGES_NO_PAGE } from '../../../../../utils';
import { RULE_MANAGER } from '../enums';
import { Permission } from '../../../../../entities';
import { DELETE_RULE_BY_ID } from '../graphql/mutations/deleteRuleById';

export type CheckRulesOutputType = {
  id: number
  merchantId: string
  applicableProducts: string // is "No Specific Products" or "All Products" or a custom string
  productCategory: string // goes in catergory field in table
  productId: string
  customizedProductId: string // prepend value to product name field seperated with -
  productName: string // needed if applicable products are not All Products or No Specific Products
  nominatedField: string // displayed in modal under nominated field
  ruleName: string // Displayed in rule name column
  customText: { // No idea what this is for no data in database has any
    id: number | null
    customText: string
    required: boolean
  }[]
  productFeed: { // show details modal Parameters for the rule to check
    id: number | null
    productFeed: string // Gets displayed after being transformed eg. from testCase to Test Case. 2 special cases in old code
    productFeedData: string // If not any product displayed in 'Data Associated with product feed
    required: boolean // show a checked displayed checkbox if true
  }[]
  status: string // status column if not active field is greyed out and checkbox set to true and disabled
  startDate: string // goes start/end date column convert to date
  endDate: string | null // left blank if null
  lastRun: string // last run column
  reviewCount: number | null // Number beside the warning triangle
  failCount: number | null // Number beside the red circle
  nfCount: number | null // Don't know what this is
  checked?: boolean // added after by frontend
}

export type MerchantRuleInfoOutput = {
  categories: SelectOption[]
  products: SelectOption[]
  rules: SelectOption[]
}

export type ProductFeed = {
  id: number,
  productFeed: string,
  productFeedData: string,
  required: boolean,
}

export type Rule = {
  status: string,
  applicableProducts: string,
  productName: string,
  startDate: string,
  customizedProductId: string,
  endDate: string| null,
  failCount: string| null,
  id: 458,
  lastRun: string,
  nominatedField: string,
  merchantId: string,
  nfCount: null,
  productCategory: string,
  productFeed: ProductFeed,
  productId: string,
  reviewCount: number | null,
  ruleName: string
  checked: boolean
}

// Function from fintel-app for transforming productFeed Names to display
export const toSentenceCase = (str: string) => {
  if (!str) return '';
  if (str === 'APY' || str === 'apyRate') return 'APY';
  if (str === 'APR' || str === 'mortgageAprRate') return 'APR';
  return str
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

const defaultProductCategoryOption: SelectOption = {
  label: 'All Categories',
  value: 'All Categories',
};

const defaultProductNameId: SelectOption[] = [
  {
    label: 'All Products',
    value: 'All Products',
  },
  {
    label: 'No Specific Products',
    value: 'No Specific Products',
  },
];

const defaultRules: SelectOption = {
  label: 'All Rules',
  value: 'All Rules',
};

const statusOptions: SelectOption[] = [
  {
    label: 'All Status',
    value: 'All Status',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
];

const STATUS_TYPES = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

let fetchId = 0;

export const useFintelRuleManager = (permissionsCodeList: string[] = []) => {
  // Global Values
  const { hookShowToast } = useToast();
  const userHook = useUserInfo();
  // Main page values
  const [productCategoriesOptions, setProductCategoriesOptions] = useState<SelectOption[]>([defaultProductCategoryOption]);
  const [productNameOptions, setProductNameOptions] = useState<SelectOption[]>(defaultProductNameId);
  const [ruleNameOptions, setRuleNameOptions] = useState<SelectOption[]>([defaultRules]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loadingMessage, setLoadingMessage] = useState<string>('Loading Dropdowns');

  // User options
  const [selectedProductCategory, setSelectedProductCategory] = useState<SelectOption>(defaultProductCategoryOption);
  const [selectedProductName, setSelectedProductName] = useState<SelectOption>(defaultProductNameId[0]);
  const [selectedStatus, setSelectedStatus] = useState<SelectOption>(statusOptions[1]);
  const [selectedRule, setSelectedRule] = useState<SelectOption>(defaultRules);
  const [selectedRecordsAmount, setSelectedRecordsAmount] = useState<SelectOption>(RECORDS_PER_PAGE_OPTIONS_WITH_150[0]);

  // Table data
  const [tableData, setTableData] = useState<CheckRulesOutputType[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalValues, setTotalValues] = useState(0);
  const [headerCheck, setHeaderCheck] = useState<boolean>(false);
  const [rulesSelected, setRulesSelected] = useState<number[]>([]);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'startDate', direction: 'desc' });
  const [tableLoading, setTableLoading] = useState<boolean>(false);

  // Details modal
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [modalRuleId, setModalRuleId] = useState<CheckRulesOutputType | undefined>();

  // Add Rule Modal
  const [isAddRuleModalOpen, setIsAddRuleModalOpen] = useState<boolean>(false);

  // Delete Rule
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRuleId, setDeleteRuleId] = useState<number>();

  // Deactivate Rule Modal
  const [isDeactivateRuleModalOpen, setIsDeactivateRuleModalOpen] = useState<boolean>(false);
  const [deactivateRuleError, setDeactivateRuleError] = useState<string>('');

  // Queries and Mutations
  const [getRuleManager, { loading: ruleManagerLoading, error: ruleDropdownError }] = useLazyQuery(LIST_FINTEL_CHECK_RULE);
  const [getRuleManagerDropdowns, { loading: ruleDropdownLoading }] = useLazyQuery(RULE_MANAGER_DROPDOWNS_INFO);

  const [deactivateRulesByIds] = useMutation(DEACTIVATE_RULES_BY_IDS);
  const [deleteRuleById] = useMutation(DELETE_RULE_BY_ID);

  const getRulesTableDataHandler = async () => {
    setLoadingMessage('Loading Rules');
    setErrorMessage('');
    setTableLoading(true);

    // Work around for preventing old requests from being set as table data if they return after newer requests.
    // Could not get the AbortControllers to work.
    const localFetchId = fetchId + 1;
    fetchId += 1;

    const { data, error } = await getRuleManager({
      variables: {
        input: {
          merchantId: userHook.hookWhoAmI.companyId?.toString(),
          applicableProducts: selectedProductName.value === 'All Products' ? null : selectedProductName.value,
          productCategory: selectedProductCategory.value === 'All Categories' ? null : selectedProductCategory.value,
          ruleName: selectedRule.value === 'All Rules' ? null : selectedRule.value,
          status: selectedStatus.value === 'All Status' ? null : selectedStatus.value,
          sortBy: sortColumn.column,
          sortOrder: sortColumn.direction === 'asc' ? -1 : 1,
          limit: Number.parseInt(selectedRecordsAmount.value, 10),
          currentPage: page,
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
        setTableData([]);
        setTotalPages(1);
        setTotalValues(0);
      },
    });
    if (error) {
      setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(error.message));
      setTableData([]);
      setTotalPages(1);
      setTotalValues(0);
    }

    if (data && data.fintelCheckRules && fetchId === localFetchId) {
      setTotalPages(Math.ceil(data.fintelCheckRules.count / Number.parseInt(selectedRecordsAmount.value, 10)));
      setTotalValues(data.fintelCheckRules.count);
      setTableData(data.fintelCheckRules.rules.map((rule: CheckRulesOutputType) => ({ ...rule, checked: rule.status !== STATUS_TYPES.ACTIVE })));
    }
    setLoadingMessage('');
    setRulesSelected([]);
    setHeaderCheck(false);
    setTableLoading(false);
  };

  const changePageHandler = (pageValue: number) => {
    setPage(pageValue);
  };

  const setDropdownListsHandler = async () => {
    setErrorMessage('');
    const { data: ruleDropdownData, error } = await getRuleManagerDropdowns({
      variables: {
        input: {
          merchantId: userHook.hookWhoAmI.companyId?.toString(),
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    let productCategoriesList = [defaultProductCategoryOption];
    let productNamesList = defaultProductNameId;
    let rulesList = [defaultRules];
    if (ruleDropdownData && ruleDropdownData.ruleManagerDropdowns && !ruleDropdownLoading && !error) {
      productCategoriesList = productCategoriesList.concat(ruleDropdownData.ruleManagerDropdowns.categories.map((cat: string) => ({ label: cat, value: cat })));
      productNamesList = productNamesList.concat(ruleDropdownData.ruleManagerDropdowns.products.map((product: string) => ({ label: product, value: product })));
      rulesList = rulesList.concat(ruleDropdownData.ruleManagerDropdowns.rules.map((rule: string) => ({ label: rule, value: rule })));
    }

    setHeaderCheck(false);
    setProductCategoriesOptions(productCategoriesList);
    setProductNameOptions(productNamesList);
    setRuleNameOptions(rulesList);
  };

  const selectProductCategoryHandler = (productCategorySelected: SelectOption) => {
    setSelectedProductCategory(productCategorySelected);
    setPage(1);
  };

  const selectProductNameHandler = (productNameSelected: SelectOption) => {
    setSelectedProductName(productNameSelected);
    setPage(1);
  };

  const setDetailsModalOpenHandler = (state: boolean) => {
    setIsDetailsModalOpen(state);
  };

  const setModalRuleIdHandler = ({ rule }: Record<string, CheckRulesOutputType>) => {
    setModalRuleId(rule);
  };

  const selectRuleHandler = (ruleSelected: SelectOption) => {
    setSelectedRule(ruleSelected);
    setPage(1);
  };

  const selectStatusHandler = (statusSelected: SelectOption) => {
    setSelectedStatus(statusSelected);
    setPage(1);
  };

  const selectRecordsAmountHandler = (statusSelected: SelectOption) => {
    setSelectedRecordsAmount(statusSelected);
    setPage(1);
  };

  const setIsAddRuleModalOpenHandler = (state: boolean) => {
    setIsAddRuleModalOpen(state);
  };

  const setIsDeactivateRuleModalOpenHandler = (state: boolean) => {
    setIsDeactivateRuleModalOpen(state);
    setDeactivateRuleError('');
  };

  const resetDropdowns = () => {
    setSelectedProductCategory(productCategoriesOptions[0]);
    setSelectedProductName(productNameOptions[0]);
    setSelectedStatus(statusOptions[1]);
    setSelectedRule(ruleNameOptions[0]);
    setPage(1);
  };

  const handleHeaderCheck = (state: boolean) => {
    // Code to get all active rule rows checked or unchecked
    const newData = tableData.map((obj) => ({ ...obj, checked: obj.status !== STATUS_TYPES.ACTIVE ? true : state }));
    setTableData(newData);
    if (state && tableData) {
      const allIds = tableData.filter((rule) => rule.status === STATUS_TYPES.ACTIVE).map((obj): number => obj.id || -1);
      setRulesSelected(allIds);
    } else {
      setRulesSelected([]);
    }
    setHeaderCheck(state);
  };

  const handleChangeCheck = (id: number, add: boolean) => {
    // Check if disabled
    const targetIndex = tableData.findIndex((obj) => obj.id === id);
    if (targetIndex === -1 || tableData[targetIndex].status === STATUS_TYPES.INACTIVE) return;

    if (add) {
      setRulesSelected([...rulesSelected, id]);
    } else {
      setRulesSelected(rulesSelected.filter((rule) => rule !== id));
    }

    const newData = [...tableData];
    newData[targetIndex].checked = add;
    setTableData(newData);

    // Check if all are checked to control header check box
    let allChecked = true;
    newData.forEach((transaction) => {
      if (transaction.checked === false) {
        allChecked = false;
      }
    });
    setHeaderCheck(allChecked);
  };

  const CheckTableRowInactive = (dataItem: CheckRulesOutputType) => dataItem.status === STATUS_TYPES.INACTIVE;

  const setSortByHandler = (dataColumn: string, _: 'asc' | 'desc' | undefined) => {
    if (sortColumn.direction === 'desc' && sortColumn.column === dataColumn) {
      setSortColumn({ column: dataColumn, direction: 'asc' });
    } else {
      setSortColumn({ column: dataColumn, direction: 'desc' });
    }
  };

  const deactivateRule = async () => {
    setDeactivateRuleError('');
    const { errors } = await deactivateRulesByIds({
      variables: {
        ids: rulesSelected,
      },
      onError(err) {
        setDeactivateRuleError(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    if (errors) {
      hookShowToast(errors[0].message);
    } else {
      setIsDeactivateRuleModalOpen(false);
      hookShowToast(RULE_MANAGER.RULE_DEACTIVATED_TOAST);

      setTableLoading(true);
      getRulesTableDataHandler();
    }
  };

  const localTableSort = () => {
    const compare = (a: CheckRulesOutputType, b: CheckRulesOutputType) => {
      if (typeof a[sortColumn.column as keyof typeof a] === 'string') {
        const value = a[sortColumn.column as keyof typeof a] as string;
        const value2 = b[sortColumn.column as keyof typeof b] as string;
        const returnValue = (value.localeCompare(value2) * (sortColumn.direction === 'asc' ? -1 : 1));
        return returnValue;
      }
      return 0;
    };
    const newTable: CheckRulesOutputType[] = structuredClone(tableData).sort(compare);
    setTableData(newTable);
  };

  const newRuleCreated = async () => {
    setTableLoading(true);
    getRulesTableDataHandler();
    setDropdownListsHandler();
  };

  const handleDelete = (selected: Rule) => {
    setDeleteRuleId(selected.id);
    setIsDeleteModalOpen(true);
  };

  const deletePostback = async () => {
    const { errors } = await deleteRuleById({
      variables: {
        id: deleteRuleId,
      },
    });
    if (errors) {
      hookShowToast(errors[0].message);
    } else {
      setIsDeleteModalOpen(false);
      hookShowToast(RULE_MANAGER.RULE_DELETED_TOAST);

      setTableLoading(true);
      getRulesTableDataHandler();
    }
  };

  // For refetching data when the paginated page changes, a dropdown values change
  useEffect(() => {
    getRulesTableDataHandler();
  }, [page, selectedProductCategory, selectedProductName, selectedStatus, selectedRule, selectedRecordsAmount]);

  // For refetching data or resorting data when sort column changes (only refetches if necessary)
  useEffect(() => {
    if (!tableData) return;
    if (totalValues <= Number.parseInt(selectedRecordsAmount.value, 10)) localTableSort();
    else getRulesTableDataHandler();
  }, [sortColumn]);

  // For setting the values in the dropdowns on page load
  useEffect(() => {
    setDropdownListsHandler();
  }, []);

  return {
    // Loading and Errors
    hookDropdownsLoading: ruleDropdownLoading,
    hookDropdownsError: ruleDropdownError,
    hookRuleManagerLoading: ruleManagerLoading,
    hookErrorMessage: errorMessage,
    hookLoadingMessage: loadingMessage,

    // Rule Table Data & Handlers
    // Table Data
    hookData: tableData,
    hookTotalPages: totalPages,
    hookPage: page,
    hookTotalValues: totalValues,
    hookHeaderCheck: headerCheck,
    hookSortColumn: sortColumn,
    hookTableLoading: tableLoading,
    // Table Handlers
    hookChangePage: changePageHandler,
    hookHeaderCheckOnChange: handleHeaderCheck,
    hookHandleChangeCheck: handleChangeCheck,
    hookCheckTableRowInactive: CheckTableRowInactive,
    hookSortByHandler: setSortByHandler,
    // Table Details Modal
    hookIsDetailsModalOpen: isDetailsModalOpen,
    hookSetIsDetailsModalOpenHandler: setDetailsModalOpenHandler,
    hookModalRuleId: modalRuleId,
    hookSetModalRuleIdHandler: setModalRuleIdHandler,

    // Rule Search Options
    // Dropdown values
    hookSelectedProductCategory: selectedProductCategory,
    hookSelectedProductName: selectedProductName,
    hookSelectedStatus: selectedStatus,
    hookSelectedRule: selectedRule,
    hookSelectedRecordsAmount: selectedRecordsAmount,
    // Dropdown change handlers
    hookSetSelectedProductCategory: selectProductCategoryHandler,
    hookSetSelectedProductName: selectProductNameHandler,
    hookSetSelectedStatus: selectStatusHandler,
    hookSetSelectedRule: selectRuleHandler,
    hookSetSelectedRecordsAmount: selectRecordsAmountHandler,
    hookResetDropdowns: resetDropdowns,
    // Dropdown options
    hookProductCategoriesOptions: productCategoriesOptions,
    hookProductNameOptions: productNameOptions,
    hookStatusOptions: statusOptions,
    hookRuleNameOptions: ruleNameOptions,
    hookRecordsAmountOptions: RECORDS_PER_PAGE_OPTIONS_WITH_150,

    // Add Rule Modal
    hookIsAddRuleModalOpen: isAddRuleModalOpen,
    hookSetIsAddRuleModalOpen: setIsAddRuleModalOpenHandler,
    hookNewRuleCreated: newRuleCreated,

    // Deactivate Role Modal
    hookIsDeavtivateButtonDisabled: rulesSelected.length < 1,
    hookIsDeactivateRuleModalOpen: isDeactivateRuleModalOpen,
    hookSetIsDeactivateRuleModalOpen: setIsDeactivateRuleModalOpenHandler,
    hookDeactivateRule: deactivateRule,
    hookDeactivateRuleError: deactivateRuleError,
    hookDeactivateLoading: false,

    // Delete Rule Modal
    handleDelete,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    deletePostback,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
