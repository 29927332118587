import { gql } from '@apollo/client';

export const LIST_FINTEL_CHECK_RULE = gql`
  query FintelCheckRules($input: FintelCheckRulesInput!) {
    fintelCheckRules(input: $input) {
      count
      rules {
        status
        applicableProducts
        productName
        startDate
        customizedProductId
        endDate
        failCount
        id
        lastRun
        nominatedField
        merchantId
        nfCount
        productCategory
        productFeed {
          id
          productFeed
          productFeedData
          required
        }
        productId
        reviewCount
        ruleName
      }
    }
  }
`;
